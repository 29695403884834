<template>
  <div class="login-main" @click="notSelectClick()">
    <p class="headertitle">{{ page == 0 ? "后台系统" : "数据驾驶舱" }}</p>
    <!-- 功能页面切换 -->
    <div class="header">
      <div class="box" @click.stop="showSelectBox()">
        <div class="pageName">
          <div>{{ page == 0 ? "后台系统" : "数据驾驶舱" }}</div>
        </div>
        <i style="color: cyan" class="el-icon-caret-bottom"></i>
      </div>
      <div v-show="showSelect">
        <div class="trangle"></div>
        <div class="selectbox">
          <div
            @click="selectClick(item.value)"
            v-for="(item, i) in pageList"
            :key="i"
            :class="hover == item.value ? 'optionColor option' : 'option'"
            @mouseover="optionHover(item.value)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 重置密码整体 -->
    <div class="main">
      <!-- 背景图 -->
      <div>
        <img class="bgImg" src="../assets/drive/loginBg.png"  alt="">
      </div>
      <div class="login">
        <i class="el-icon-circle-close error" @click="backLogin()" style="color: rgb(35, 250, 242);font-size: 1.56vw;"></i>
        <div class="title">重置密码</div>
        <!-- 新密码 -->
        <div class="input">
          <img
            src="../assets/drive/mm_icon.svg"
            @click="pswshowNew()"
          />
          <input
            style="color: #fff"
            placeholder="请输入新密码"
            v-model="newPsw"
            type="password"
            ref="passwordNew"
            @blur="newPswBlur(newPsw)"
            @input="verifyData(newPsw, 0)"
          />
          <div v-if="newPswErr" class="text">{{ newPswErrInfo }}</div>
          <div v-if="limitNewPsw" class="text">
            密码不能超过{{ maxLen }}个字符
          </div>
        </div>
        <!-- 密码 -->
        <div class="input">
          <img
            src="../assets/drive/mm_icon.svg"
          
            @click="pswshow()"
          />
          <input
            style="color: #fff"
            placeholder="请再次输入新密码"
            v-model="psw"
            type="password"
            ref="password"
            @blur="pswBlur(psw)"
            @input="verifyData(psw, 1)"
          />
          <div v-if="pswErr" class="text">{{ pswErrInfo }}</div>
          <div class="text" v-if="limitPsw && !pswErr">
            密码超过{{ maxLen }}个字符
          </div>
        </div>
        <!-- 确认按钮 -->
        <div class="btn" @click="revisePSW()">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: "",
      //   新密码
      newPsw: "",
      //   确认密码
      psw: "",
      // 新密码错误提示信息是否显示
      newPswErr: false,
      newPswErrInfo: "请输入新密码",
      // 确认密码错误提示信息是否显示
      pswErr: false,
      pswErrInfo: "请输入确认密码",
      // 页面切换
      pageList: [
        { name: "后台系统", value: 0 },
        { name: "数据驾驶舱", value: 1 },
      ],
      page: 0,
      hover: 0,
      // 下拉框是否显示
      showSelect: false,
      maxLen: 20,
    };
  },
  mounted() {
    console.log(this.$route.params.id)
    this.page = this.$route.params.id;
    this.hover = this.$route.params.id;
    console.log(this.page,this.hover)
  },
  computed: {
    // 确认密码限制字符
    limitPsw() {
      return this.psw.length > this.maxLen;
    },
    // 新密码限制字符
    limitNewPsw() {
      return this.newPsw.length > this.maxLen;
    },
  },
  methods: {
    // 输入框校验
    verifyData(str, i) {
      if (str.length > 0) {
        if (i == 0) {
          this.newPswErr = false;
        } else {
          if (this.psw != this.newPsw) {
            this.pswErr = true;
            this.pswErrInfo = "两次输入密码不同";
          } else {
            this.pswErr = false;
          }
        }
      }
    },
    // 检验新密码是否为空
    newPswBlur(e) {
      if (e == "" || e == null) {
        this.newPswErr = true;
      }
    },
    // 检验确认密码是否为空
    pswBlur(e) {
      if (e == "" || e == null) {
        this.pswErr = true;
      }
    },
    // 关闭下拉选择框
    notSelectClick() {
      this.showSelect = false;
    },
    // 显示下拉框
    showSelectBox() {
      this.showSelect = true;
    },

    // 页面选择
    selectClick(i) {
      this.page = i;
      this.showSelect = false;
    },
    // select框的optionhover
    optionHover(i) {
      this.hover = i;
    },
    // 确认修改密码按钮
    revisePSW() {
      if (
        (this.newPsw == "" || this.newPsw == null) &&
        (this.psw == "" || this.psw == null)
      ) {
        this.newPswErr = true;
        this.pswErr = true;
      } else if (this.newPsw == "" || this.newPsw == null) {
        this.newPswErr = true;
      } else if (this.psw == "" || this.psw == null) {
        this.pswErr = true;
      } else {
        this.$router.push({ name: "/Login", params: { id: this.page } });
        // localStorage.clear();
      }
    },
    // 新密码是否可以看见
    pswshowNew() {
      if (this.$refs.passwordNew.type == "password") {
        this.$refs.passwordNew.type = "text";
      } else {
        this.$refs.passwordNew.type = "password";
      }
    },
    // 密码是否可以看见
    pswshow() {
      if (this.$refs.password.type == "password") {
        this.$refs.password.type = "text";
      } else {
        this.$refs.password.type = "password";
      }
    },
    // 是否记住密码
    remeberPsw() {
      this.checked = !this.checked;
    },
    // 返回登录页
    backLogin(){
      this.$router.push('/Login')
    },
  },
};
</script>
<style lang="scss" scoped>
.headertitle {
  width: 100%;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(238, 238, 238, 1),
    rgba(113, 244, 236, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  // text-fill-color: transparent;
  font-family: PingFang SC;
  font-size: 2.3vw;
}
.login-main {
  background: url("../assets/drive/big-screen-backimage.svg");
  margin: 0px; //外边距0
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;

  // 头部切换按钮
  .header {
    color: #ffffff;
    cursor: pointer;
    position: relative;
    .box {
      position: absolute;
      top: 0px;
      right: 1.04vw;
      display: flex;
      justify-content: space-between;
      width: 6.25vw;
      min-width: 95px;
      font-size: 0.83vw;
      padding: 0.46vh 0.26vw;
      line-height: 2.78vh;
      box-sizing: border-box;
      background: rgb(0, 7, 11);
      border: 0.05vw solid rgb(11, 166, 167);
      box-shadow: inset 0px 0.05vw 0.89vw rgb(11, 166, 167);
      border-radius: 7px;
      .pageName {
        width: 4.69vw;
        min-width: 60px;
        text-align: center;
      }

      // 下拉图标
      .el-icon-caret-bottom {
        font-size: 2.5vh;
        border-left: 0.05vw solid rgb(11, 166, 167);
      }
    }

    // 倒三角
    .trangle {
      position: absolute;
      top: 3.69vh;
      right: 4.68vw;
      width: 0;
      height: 0;
      border-bottom: 10px solid cyan;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    // 下拉框
    .selectbox {
      position: absolute;
      top: 4.14vh;
      right: 1vw;
      width: 6.25vw;
      min-width: 90px;
      font-size: 0.83vw;
      background: rgb(0, 7, 11);
      color: #fff;
      margin: 5px;
      padding: 5px 0;
      border: 0.05vw solid rgb(11, 166, 167);
      border-radius: 5px;
      box-sizing: border-box;
      .optionColor {
        background-color: rgb(11, 166, 167);
      }
      // .option:hover {
      //   background-color: rgb(11, 166, 167);
      //   color: #fff;
      // }

      .option {
        padding: 5px;
      }
    }
  }

  // 重置整体
  .main {
    position: absolute;
    width: 30.31vw;
    left: calc(50% - 15.155vw);
    height: 36.11vh;
    top: calc(50% - 22vh);
    position: relative;
    .bgImg{
      width: 30.31vw;
      height: 36.11vh;
    }
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 1.04vw;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    margin: 4.6vh auto 1.85vh;
    
  }

  // 登录模块
  .login {
    width: 30.31vw;
    height: 36.11vh;
    border-radius: 1.04vw;
    // background-color: #000;
    text-align: center;
    padding: 2.59vh 2.45vw;
    box-sizing: border-box;
    position: absolute;
    top:0;
    left: 0;
    .error{
      color: #fff;
      position: absolute;
      right: 1.04vw;
    }
    .input {
      width: 17.55vw;
      height: 4.26vh;
      background: rgba(0, 7, 11, 0.5);
      border: 1px solid rgb(11, 166, 167);
      border-radius: 7px;
      display: flex;
      align-items: center;
      margin: 1.85vh auto;
      position: relative;
      img {
        display: inline-block;
        align-items: middle;
        width:0.88vw;
        min-width: 20px;
        margin: 0 0.52vw;
      }
      // 错误提示
      .text {
        color: red;
        position: absolute;
        top: 4.07vh;
        left: 2px;
        font-size: 0.73vw;
      }

      // 输入框
      input {
        width: 14vw;
        height: 3.7vh;
        outline: none;
        border: none;
        background: rgba(0, 7, 11, 0.1);
        color: "#fff";
        position: relative;
      }

      input[type="password"] {
        font-family: Arial Source Han Sans CN;
      }

      input::-webkit-input-placeholder {
        color: #c0c0c0;
        font-size: 0.73vw;
      }

      /* Mozilla Firefox 4 to 18 */
      input:-moz-placeholder {
        color: #c0c0c0;
        opacity: 1;
        font-size: 0.73vw;
      }

      /* Mozilla Firefox 19+ */
      input::-moz-placeholder {
        color: #c0c0c0;
        opacity: 1;
        font-size: 0.73vw;
      }

      /* Internet Explorer 10+ */
      input:-ms-input-placeholder {
        color: #c0c0c0;
        font-size: 0.73vw;
      }

    }

    // 登录按钮
    .btn {
      width: 17.55vw;
      height: 3.7vh;
      background: rgb(98, 255, 246);
      border: 1px solid rgb(11, 166, 167);
      box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
      border-radius: 7px;
      color: #ffffff;
      line-height: 3.7vh;
      font-size: 1.04vw;
      font-family: PingFang SC;
      font-weight: 500;
      margin-left: 3.64vw;
    }
  }
}
</style>
>
